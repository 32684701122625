<template>
  <van-row style="line-height: 44px">
    <van-col :span="width" :class="[`h-title-line-left-border-${borderColor}`]">
      <slot name="left">
        <div class="h-line-left text-l">
          <slot name="title">
            <span
              class="h-line-left__title"
              v-if="title"
              :class="['f-s-16', titleClass, isBolder ? 'f-w-8' : '']"
            >
              {{ title }}
            </span>
          </slot>
          <slot name="subtitle">
            <span
              class="h-line-left__subtitle"
              v-if="subtitle"
              :class="['f-s-12 f-c-9', subtitleClass]"
            >
              {{ subtitle }}
            </span>
          </slot>
        </div>
      </slot>
    </van-col>
    <van-col :span="24 - width">
      <slot name="right"></slot>
    </van-col>
  </van-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Col, Row } from "vant";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    width: {
      type: Number,
      default: 18,
    },
    borderColor: {
      validator: (value: string) => {
        return ["1", "2", "3"].includes(value);
      },
      type: String,
      default: "1",
    },
    isBolder: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "标题",
    },
    titleClass: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    subtitleClass: {
      type: String,
      default: "",
    },
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
});
</script>

<style lang="less" scoped>
.h-line-left__title {
  margin-right: 10px;
}
</style>