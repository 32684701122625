
import { defineComponent, reactive, onMounted, toRefs } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Store } from "@/store/";
import { Col, Row, Grid, GridItem, Icon } from "vant";
import Selector from "@/components/select/selector.vue";
import TitleLine from "@/components/titleLine/titleLine.vue";
import cv from "@/components/chart/echartsTable.vue";
import {
  global_option,
  total_option,
  achievement_option,
  origin_option,
} from "@/utils/echartsOption";
import { deepObjectMerges } from "@/utils/app";

export default defineComponent({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    Selector,
    TitleLine,
    cv,
  },
  setup() {
    const store: Store = useStore();
    const router = useRouter();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      iconList: ["cubes-solid", "md-account_balance", "lianmeng", "lianmeng1"],
      reportDataYear: Array<Achievement>(),
      totalData: {},
      achievementData: {},
      originData: {},
      is_region: false,
      totalStatusYear: store.state.app.reportConfig!.totalStatusYear,
      originStatusYear: store.state.app.reportConfig!.originStatusYear,
    });

    const onSelect = (index: number, action: Action) => {
      store.commit("setAIData", { key: "totalStatusYear", val: action.text });
      store
        .dispatch("actReportDataYear", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          year: action.text,
        })
        .then((res) => {
          if (res.status === 200) {
            dataMap.reportDataYear = res.data_list.list;
            let datas = {
              title: {
                text: `全年总业绩：${res.data_list.received_count}(万元)`,
              },
              legend: {
                data: [] as string[],
              },
              series: [
                {
                  data: [] as { value: number; name: string }[],
                },
              ],
            };
            res.data_list.list.forEach((el) => {
              datas.legend.data.push(el.name);
              datas.series[0].data.push({
                value: el.amount,
                name: el.name,
              });
            });
            dataMap.totalData = deepObjectMerges([
              JSON.parse(JSON.stringify(global_option)),
              JSON.parse(JSON.stringify(total_option)),
              datas,
            ]);
          }
        });
    };

    const getReportDataYearMon = (): any => {
      store
        .dispatch("actReportDataYearMon", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            let datas = {
              legend: { data: res.data_list.year_list },
              series: [] as {}[],
            };
            let isNone = true;
            res.data_list.year_list.forEach((key) => {
              let list = res.data_list.list[key].replace(/-1/g, "").split(",");
              if (isNone) {
                list.forEach((item) => {
                  if (item) {
                    isNone = false;
                  }
                });
              }
              datas.series.push({
                type: "line",
                name: key,
                data: list,
              });
            });
            if (!isNone) {
              dataMap.achievementData = deepObjectMerges([
                JSON.parse(JSON.stringify(global_option)),
                JSON.parse(JSON.stringify(achievement_option)),
                datas,
              ]);
            } else {
              let option = {
                yAxis: {
                  max: 50000,
                  min: 0,
                },
              };
              dataMap.achievementData = deepObjectMerges([
                JSON.parse(JSON.stringify(global_option)),
                option,
                JSON.parse(JSON.stringify(achievement_option)),
                datas,
              ]);
            }
          }
        })
        .catch((err) => {});
    };

    const getReportDataChannel = () => {
      store
        .dispatch("actReportDataChannel", {
          dept_id: dept_id,
          userid: userid,
          username: username,
        })
        .then((res) => {
          if (res.status === 200) {
            dataMap.is_region = !!res.data_list.is_region;
            let option = JSON.parse(JSON.stringify(origin_option));
            option.legend = { data: [] };
            let getLegend = true;
            res.data_list.year_list.forEach((year, index) => {
              if (getLegend) {
                res.data_list.list[year].forEach((item) => {
                  option.legend.data.push(item.name);
                });
                getLegend = false;
              }
              option.title[index]["text"] = year;
              option.series[index]["name"] = year;
              option.series[index]["data"] = JSON.parse(
                JSON.stringify(res.data_list.list[year]).replace(
                  /sum/g,
                  "value"
                )
              );
            });
            dataMap.originData = deepObjectMerges([
              JSON.parse(JSON.stringify(global_option)),
              option,
            ]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const selectOrigin = (index: number, action: Action) => {
      store.commit("setAIData", { key: "originStatusYear", val: action.text });
    };

    const goto = (type: string) => {
      store.commit("setAIData", { key: "totalStatusType", val: type });
      router.push({
        name: "area",
      });
    };

    const goToSource = () => {
      store.commit("setAIData", { key: "channelGroup", val: "" });
      router.push({
        name: "source",
      });
    };

    const callback = (obj: any, el: any) => {
      store.commit("setAIData", { key: "channelGroup", val: obj.name });
      router.push({
        name: "source",
      });
      el.dispatchAction({
        type: "legendSelect",
        name: obj.name,
      });
    };

    onMounted(() => {
      getReportDataYearMon();
      getReportDataChannel();
    });
    return {
      ...toRefs(dataMap),
      store,
      onSelect,
      selectOrigin,
      callback,
      goto,
      goToSource,
    };
  },
});
