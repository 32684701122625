
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { Progress, Collapse, CollapseItem } from "vant";
import TitleLine from "@/components/titleLine/titleLine.vue";
import cv from "@/components/chart/echartsTable.vue";
import {
  global_option,
  site_option,
  complaints_option,
} from "@/utils/echartsOption";
import { deepObjectMerges } from "@/utils/app";

export default defineComponent({
  components: {
    [Progress.name]: Progress,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    TitleLine,
    cv,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      activeName: "",
      siteBarData: {},
      satisfaction: -1,
      complaintDatas: [] as unknown[],
    });

    const getDecorationMultipleYear = () => {
      store
        .dispatch("actGetDecorationMultipleYear", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            let option = JSON.parse(JSON.stringify(site_option));
            option.legend = { data: [] };
            option.grid.top = "10%";
            option.legend.bottom = "5%";
            option.xAxis[0]["data"] = res.data_list.year_list;
            type NameList = keyof GetDecorationMultipleYearReq["name_list"];
            Object.keys(res.data_list.name_list).forEach((key, index) => {
              option.legend.data.push(res.data_list.name_list[key as NameList]);
              option.series[index].name =
                res.data_list.name_list[key as NameList];
              option.series[index]["data"] = [];
              res.data_list.year_list.forEach((item) => {
                option.series[index].data.push(
                  res.data_list.list[item][key as NameList]
                );
              });
            });
            dataMap.siteBarData = deepObjectMerges([
              JSON.parse(JSON.stringify(global_option)),
              option,
            ]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getOtherMultipleYear = () => {
      store
        .dispatch("actGetOtherMultipleYear", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.satisfaction = res.data_list.current_data.satisfaction;
            type NameList = keyof GetOtherMultipleYearReq["name_list"];
            type MultipleYearData =
              keyof GetOtherMultipleYearReq["multiple_year_data"];
            for (let key in res.data_list.name_list) {
              if (key === "satisfaction") continue;
              let title = res.data_list.name_list[key as NameList];
              let days = res.data_list.current_data[key as NameList];
              let option = JSON.parse(JSON.stringify(complaints_option));
              option.legend = { data: res.data_list.year_list };
              res.data_list.year_list.forEach((year, ids) => {
                option.series[ids].name = year;
                let years =
                  res.data_list.multiple_year_data[key as MultipleYearData];
                option.series[ids].data = [
                  res.data_list.multiple_year_data[key as MultipleYearData][
                    year
                  ],
                ];
              });
              dataMap.complaintDatas.push({
                title: title,
                days: days,
                option: deepObjectMerges([
                  JSON.parse(JSON.stringify(global_option)),
                  option,
                ]),
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getDecorationMultipleYear();
    getOtherMultipleYear();
    return {
      ...toRefs(dataMap),
    };
  },
});
