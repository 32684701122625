
import { defineComponent } from "vue";
import { Col, Row } from "vant";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    width: {
      type: Number,
      default: 18,
    },
    borderColor: {
      validator: (value: string) => {
        return ["1", "2", "3"].includes(value);
      },
      type: String,
      default: "1",
    },
    isBolder: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "标题",
    },
    titleClass: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    subtitleClass: {
      type: String,
      default: "",
    },
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
});
