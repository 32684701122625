
import { defineComponent, ref, reactive } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { NavBar, Tab, Tabs } from "vant";
import marketing from "./components/marketing.vue";
import site from "./components/site.vue";
import resources from "./components/resources.vue";
import comprehensive from "./components/comprehensive.vue";

export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    marketing,
    site,
    comprehensive,
    resources,
  },
  setup() {
    const store: Store = useStore();
    const active = ref(store.state.app.reportConfig!.node || 0);
    const navs = reactive([
      { text: "营销简报", name: "marketing" },
      { text: "工地简报", name: "site" },
      { text: "人力资源", name: "resources" },
      { text: "综合数据", name: "comprehensive" },
    ]);
    return {
      active,
      navs,
    };
  },
});
