<template>
  <div class="marketing">
    <div class="annual bg-main p-v-10 m-v-10">
      <TitleLine
        borderColor="1"
        title="年度指标"
        subtitle="(数据半小时更新一次)"
        class="p-h-10"
      >
        <template v-slot:right>
          <Selector
            :actions="store.getters.G_REPORT_YEAR_LIST"
            placement="left-start"
            initVal
            :defaultValue="totalStatusYear"
            @onSelect="onSelect"
          ></Selector>
        </template>
      </TitleLine>
      <cv :datas="totalData" class="m-b-10"></cv>
      <van-grid :column-num="2">
        <van-grid-item
          v-for="(item, index) in reportDataYear"
          :key="index"
          class="detail-item iconfont icon-right"
          @click="goto(item.type)"
        >
          <div class="type">
            <van-icon class-prefix="iconfont" :name="iconList[index]" />
            <span class="type-text f-w-8">{{ item.name }}</span>
            <span class="type-desc f-s-10 f-c-6">(万元)</span>
          </div>
          <p class="money f-s-16 f-w-8" style="color: #e51c23">
            {{ item.amount || 0 }}
          </p>
          <p class="num f-s-16 text-l p-l-20" v-if="item.received_count">
            <span class="f-c-6">来单：</span>
            <span style="color: #f9b90e">{{ item.received_count || 0 }}</span>
            <span class="f-s-10 p-l-10">(个)</span>
          </p>
          <p class="num f-s-16 text-l p-l-20" v-if="item.agreement_count">
            <span class="f-c-6">协议：</span>
            <span style="color: #f9b90e">{{ item.agreement_count || 0 }}</span>
            <span class="f-s-10 p-l-10">(个)</span>
          </p>
          <p class="num f-s-16 text-l p-l-20" v-if="item.sign_count">
            <span class="f-c-6">签单：</span>
            <span style="color: #f9b90e">{{ item.sign_count || 0 }}</span>
            <span class="f-s-10 p-l-10">(个)</span>
          </p>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="bg-main p-b-10 m-v-10">
      <TitleLine
        borderColor="2"
        title="三年业绩数据对比"
        class="p-h-10"
      ></TitleLine>
      <cv :datas="achievementData" height="60vw"></cv>
    </div>
    <div class="bg-main p-v-10">
      <TitleLine borderColor="3" title="三年来单途径数据对比" class="p-h-10">
        <template v-slot:subtitle>
          <span
            class="groupTip f-w-5 f-s-10"
            @click="goToSource"
            v-if="!is_region"
            >点击查看详情</span
          >
        </template>
        <template v-slot:right>
          <Selector
            :actions="store.getters.G_REPORT_YEAR_LIST"
            placement="left-start"
            initVal
            :defaultValue="originStatusYear"
            @onSelect="selectOrigin"
          ></Selector>
        </template>
      </TitleLine>
      <cv
        :datas="originData"
        height="66.7vw"
        :event="
          is_region
            ? {
                callback: callback,
                eventName: 'legendselectchanged',
              }
            : undefined
        "
      ></cv>
      <div class="tips text-c f-s-12 f-c-6" v-if="is_region">
        点击以上图标查看详情
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, toRefs } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Store } from "@/store/";
import { Col, Row, Grid, GridItem, Icon } from "vant";
import Selector from "@/components/select/selector.vue";
import TitleLine from "@/components/titleLine/titleLine.vue";
import cv from "@/components/chart/echartsTable.vue";
import {
  global_option,
  total_option,
  achievement_option,
  origin_option,
} from "@/utils/echartsOption";
import { deepObjectMerges } from "@/utils/app";

export default defineComponent({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    Selector,
    TitleLine,
    cv,
  },
  setup() {
    const store: Store = useStore();
    const router = useRouter();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      iconList: ["cubes-solid", "md-account_balance", "lianmeng", "lianmeng1"],
      reportDataYear: Array<Achievement>(),
      totalData: {},
      achievementData: {},
      originData: {},
      is_region: false,
      totalStatusYear: store.state.app.reportConfig!.totalStatusYear,
      originStatusYear: store.state.app.reportConfig!.originStatusYear,
    });

    const onSelect = (index: number, action: Action) => {
      store.commit("setAIData", { key: "totalStatusYear", val: action.text });
      store
        .dispatch("actReportDataYear", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          year: action.text,
        })
        .then((res) => {
          if (res.status === 200) {
            dataMap.reportDataYear = res.data_list.list;
            let datas = {
              title: {
                text: `全年总业绩：${res.data_list.received_count}(万元)`,
              },
              legend: {
                data: [] as string[],
              },
              series: [
                {
                  data: [] as { value: number; name: string }[],
                },
              ],
            };
            res.data_list.list.forEach((el) => {
              datas.legend.data.push(el.name);
              datas.series[0].data.push({
                value: el.amount,
                name: el.name,
              });
            });
            dataMap.totalData = deepObjectMerges([
              JSON.parse(JSON.stringify(global_option)),
              JSON.parse(JSON.stringify(total_option)),
              datas,
            ]);
          }
        });
    };

    const getReportDataYearMon = (): any => {
      store
        .dispatch("actReportDataYearMon", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            let datas = {
              legend: { data: res.data_list.year_list },
              series: [] as {}[],
            };
            let isNone = true;
            res.data_list.year_list.forEach((key) => {
              let list = res.data_list.list[key].replace(/-1/g, "").split(",");
              if (isNone) {
                list.forEach((item) => {
                  if (item) {
                    isNone = false;
                  }
                });
              }
              datas.series.push({
                type: "line",
                name: key,
                data: list,
              });
            });
            if (!isNone) {
              dataMap.achievementData = deepObjectMerges([
                JSON.parse(JSON.stringify(global_option)),
                JSON.parse(JSON.stringify(achievement_option)),
                datas,
              ]);
            } else {
              let option = {
                yAxis: {
                  max: 50000,
                  min: 0,
                },
              };
              dataMap.achievementData = deepObjectMerges([
                JSON.parse(JSON.stringify(global_option)),
                option,
                JSON.parse(JSON.stringify(achievement_option)),
                datas,
              ]);
            }
          }
        })
        .catch((err) => {});
    };

    const getReportDataChannel = () => {
      store
        .dispatch("actReportDataChannel", {
          dept_id: dept_id,
          userid: userid,
          username: username,
        })
        .then((res) => {
          if (res.status === 200) {
            dataMap.is_region = !!res.data_list.is_region;
            let option = JSON.parse(JSON.stringify(origin_option));
            option.legend = { data: [] };
            let getLegend = true;
            res.data_list.year_list.forEach((year, index) => {
              if (getLegend) {
                res.data_list.list[year].forEach((item) => {
                  option.legend.data.push(item.name);
                });
                getLegend = false;
              }
              option.title[index]["text"] = year;
              option.series[index]["name"] = year;
              option.series[index]["data"] = JSON.parse(
                JSON.stringify(res.data_list.list[year]).replace(
                  /sum/g,
                  "value"
                )
              );
            });
            dataMap.originData = deepObjectMerges([
              JSON.parse(JSON.stringify(global_option)),
              option,
            ]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const selectOrigin = (index: number, action: Action) => {
      store.commit("setAIData", { key: "originStatusYear", val: action.text });
    };

    const goto = (type: string) => {
      store.commit("setAIData", { key: "totalStatusType", val: type });
      router.push({
        name: "area",
      });
    };

    const goToSource = () => {
      store.commit("setAIData", { key: "channelGroup", val: "" });
      router.push({
        name: "source",
      });
    };

    const callback = (obj: any, el: any) => {
      store.commit("setAIData", { key: "channelGroup", val: obj.name });
      router.push({
        name: "source",
      });
      el.dispatchAction({
        type: "legendSelect",
        name: obj.name,
      });
    };

    onMounted(() => {
      getReportDataYearMon();
      getReportDataChannel();
    });
    return {
      ...toRefs(dataMap),
      store,
      onSelect,
      selectOrigin,
      callback,
      goto,
      goToSource,
    };
  },
});
</script>

<style lang="less" scoped>
.annual {
  .detail-item {
    & :deep(.van-grid-item__content--center) {
      justify-content: start;
    }
    .type {
      line-height: 3.142857rem;
    }

    .money {
      height: 2.142857rem;
      line-height: 2.142857rem;
    }

    .num {
      height: 1.785714rem;
      width: 100%;
      line-height: 1.785714rem;
    }
    &::before {
      position: absolute;
      top: 50%;
      right: 0.35714rem;
      font-size: 1.571429rem;
      font-weight: 900;
      color: #aaa;
      z-index: 99;
    }
  }
}
.groupTip {
  color: red;
}
</style>