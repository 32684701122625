
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";
import { Row, Col, Button } from "vant";
import TitleLine from "@/components/titleLine/titleLine.vue";
import Selector from "@/components/select/selector.vue";
import cv from "@/components/chart/echartsTable.vue";
import simpleTable from "@/components/table/simpleTable.vue";
import { moveDom } from "@/directives/moveDom";
import { global_option, total_option } from "@/utils/echartsOption";
import { deepObjectMerges } from "@/utils/app";

export default defineComponent({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    TitleLine,
    Selector,
    cv,
    simpleTable,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;
    let router = useRouter();

    const dataMap = reactive({
      workList: [{ text: "在职" }, { text: "离职" }],
      columns: ["区域", "人数", "占比"],
      move: {},
      title: "",
      numberYear:
        store.state.app.reportConfig!.numberYear ||
        store.state.app.reportYearList[0] + "",
      degreeYear:
        store.state.app.reportConfig!.degreeYear ||
        store.state.app.reportYearList[0] + "",
      departYear:
        store.state.app.reportConfig!.departYear ||
        store.state.app.reportYearList[0] + "",
      numberWork: store.state.app.reportConfig!.numberWork || "在职",
      degreeWork: store.state.app.reportConfig!.degreeWork || "在职",
      departWork: store.state.app.reportConfig!.departWork || "在职",
      region: store.state.app.reportConfig!.region || "",
      is_region: false,
      isTop: (store.state.app.reportConfig?.isTop || false) as boolean,
      numberData: [] as any[],
      degreeData: {},
      departData: {},
    });

    const getReportShrUserNumber = () => {
      store
        .dispatch("actReportShrUserNumber", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          year: dataMap.degreeYear,
          is_work: dataMap.degreeWork == "在职" ? 1 : 0,
          region: dataMap.region,
        })
        .then((res) => {
          dataMap.title = res.title;
          dataMap.is_region = !!res.is_region;
          dataMap.numberData = [];
          let sum = 0;
          res.data.forEach((item) => {
            dataMap.numberData.push({
              name: item.name,
              datas: [item.name, item.number, item.percent],
              fnumber: item.fnumber,
            });
            sum += parseInt(item.number);
          });
          dataMap.numberData.push({
            name: "汇总",
            datas: ["汇总", sum + "", "100%"],
            fnumber: "",
          });
          getReportShrUserDegree();
          getReportShrUserDepart();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getReportShrUserDegree = () => {
      store
        .dispatch("actReportShrUserDegree", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          year: dataMap.degreeYear,
          is_work: dataMap.degreeWork == "在职" ? 1 : 0,
          region: dataMap.region,
        })
        .then((res) => {
          let datas = {
            legend: {
              data: [] as any[],
              bottom: "5%",
            },
            series: [
              {
                data: [] as any[],
              },
            ],
          };
          res.data.forEach((el, index) => {
            datas.legend.data.push(el.title);
            datas.series[0].data.push({
              value: parseInt(el.number),
              name: el.title,
            });
          });
          dataMap.degreeData = deepObjectMerges([
            JSON.parse(JSON.stringify(global_option)),
            JSON.parse(JSON.stringify(total_option)),
            datas,
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getReportShrUserDepart = () => {
      store
        .dispatch("actReportShrUserDepart", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          year: dataMap.departYear,
          is_work: dataMap.departWork == "在职" ? 1 : 0,
          region: dataMap.region,
        })
        .then((res) => {
          let option = {
            legend: {
              x: "center",
              y: "bottom",
              data: [] as any[],
            },
            tooltip: {
              show: true,
              type: "item",
            },
            xAxis: {
              type: "value",
              axisLine: {
                show: true,
              },
            },
            yAxis: {
              type: "category",
              splitLine: { show: true },
            },
            grid: {
              top: "8%",
              left: "8%",
              right: "8%",
              bottom: "16.7%",
              containLabel: true,
            },
            series: [] as any[],
          };
          let colors = ["#91A7D0", "#F5C9C8", "#EB9587", "#786075", "#8F5F50"];
          res.data.forEach((item, index) => {
            let serie = {
              type: "bar",
              markLine: {
                data: [
                  {
                    type: "max",
                    name: "最大值",
                  },
                ],
                symbol: ["none", "arrow"],
                symbolSize: [6, 6],
              },
              name: item.title,
              data: [parseInt(item.number)],
              color: colors[index % 5],

              label: {
                show: true,
                position: "right",
              },
            };
            option.series.push(serie);
            option.legend.data.push(item.title);
          });
          dataMap.departData = option;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const selectNumberYear = (index: number, action: Action) => {
      dataMap.numberYear = action.text;
      getReportShrUserNumber();
    };
    const selectDegreeYear = (index: number, action: Action) => {
      dataMap.degreeYear = action.text;
      getReportShrUserDegree();
    };
    const selectDepartYear = (index: number, action: Action) => {
      dataMap.departYear = action.text;
      getReportShrUserDepart();
    };
    const selectNumberWork = (index: number, action: Action) => {
      dataMap.numberWork = action.text;
      getReportShrUserNumber();
    };
    const selectDegreeWork = (index: number, action: Action) => {
      dataMap.degreeWork = action.text;
      getReportShrUserDegree();
    };
    const selectDepartWork = (index: number, action: Action) => {
      dataMap.departWork = action.text;
      getReportShrUserDepart();
    };

    const handleClick = (item: any) => {
      if (!dataMap.is_region) return;
      if (item.name === "汇总") return;
      dataMap.region = item.fnumber;
      dataMap.isTop = true;
      getReportShrUserNumber();
    };

    const back = () => {
      dataMap.isTop = false;
      dataMap.region = "";
      (dataMap.move as HTMLElement).style.top = "80%";
      (dataMap.move as HTMLElement).style.right = "20px";
      (dataMap.move as HTMLElement).style.left = "unset";
      getReportShrUserNumber();
    };

    const goToDetails = () => {
      store.commit("setAIData", { key: "node", val: 2 });
      store.commit("setAIData", { key: "region", val: dataMap.region });
      store.commit("setAIData", { key: "numberYear", val: dataMap.numberYear });
      store.commit("setAIData", { key: "degreeYear", val: dataMap.degreeYear });
      store.commit("setAIData", { key: "departYear", val: dataMap.departYear });
      store.commit("setAIData", { key: "numberWork", val: dataMap.numberWork });
      store.commit("setAIData", { key: "degreeWork", val: dataMap.degreeWork });
      store.commit("setAIData", { key: "departWork", val: dataMap.departWork });
      store.commit("setAIData", { key: "isTop", val: dataMap.isTop });
      router
        .push({
          name: "resourceDetails",
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getReportShrUserNumber();

    return {
      ...toRefs(dataMap),
      store,
      selectNumberYear,
      selectDegreeYear,
      selectDepartYear,
      selectNumberWork,
      selectDegreeWork,
      selectDepartWork,
      handleClick,
      back,
      goToDetails,
    };
  },
  directives: {
    move: moveDom,
  },
});
