<template>
  <div class="site">
    <div class="bg-main m-v-10">
      <TitleLine
        borderColor="1"
        title="三年度指标对比"
        subtitle="(数据每天更新一次)"
        class="p-h-10"
      ></TitleLine>
      <cv height="80vw" :datas="siteBarData"></cv>
    </div>
    <div class="bg-main">
      <TitleLine
        :width="22"
        borderColor="2"
        title="效率、质量、满意度"
        subtitle="(数据每天更新一次)"
        class="p-h-10"
      ></TitleLine>
      <div class="p-h-20">
        <p>本年度完工客户满意率</p>
        <div class="p-v-20">
          <van-progress
            :percentage="
              satisfaction < 0 ? 0 : satisfaction > 100 ? 100 : satisfaction
            "
            :pivot-text="
              satisfaction < 0
                ? '暂无数据'
                : satisfaction > 100
                ? '100%'
                : satisfaction + '%'
            "
            stroke-width="8"
            color="linear-gradient(to right, #2CBE71, #0D957F)"
          />
        </div>
        <p class="f-s-12 f-c-6 font-line-height-30">
          完工客户满意率指的是完工客户评价满意的工地
        </p>
      </div>
      <van-collapse v-model="activeName" accordion class="site-table">
        <van-collapse-item
          v-for="(item, index) in complaintDatas"
          :key="index"
          :name="index"
        >
          <template #title>
            <p>
              <span class="f-w-8 f-s-18">{{ item.title }}</span>
              <span class="font-main f-s-16 f-w-7">{{ item.days }}</span>
            </p>
          </template>
          <cv height="40vw" :datas="item.option"></cv>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { Progress, Collapse, CollapseItem } from "vant";
import TitleLine from "@/components/titleLine/titleLine.vue";
import cv from "@/components/chart/echartsTable.vue";
import {
  global_option,
  site_option,
  complaints_option,
} from "@/utils/echartsOption";
import { deepObjectMerges } from "@/utils/app";

export default defineComponent({
  components: {
    [Progress.name]: Progress,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    TitleLine,
    cv,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      activeName: "",
      siteBarData: {},
      satisfaction: -1,
      complaintDatas: [] as unknown[],
    });

    const getDecorationMultipleYear = () => {
      store
        .dispatch("actGetDecorationMultipleYear", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            let option = JSON.parse(JSON.stringify(site_option));
            option.legend = { data: [] };
            option.grid.top = "10%";
            option.legend.bottom = "5%";
            option.xAxis[0]["data"] = res.data_list.year_list;
            type NameList = keyof GetDecorationMultipleYearReq["name_list"];
            Object.keys(res.data_list.name_list).forEach((key, index) => {
              option.legend.data.push(res.data_list.name_list[key as NameList]);
              option.series[index].name =
                res.data_list.name_list[key as NameList];
              option.series[index]["data"] = [];
              res.data_list.year_list.forEach((item) => {
                option.series[index].data.push(
                  res.data_list.list[item][key as NameList]
                );
              });
            });
            dataMap.siteBarData = deepObjectMerges([
              JSON.parse(JSON.stringify(global_option)),
              option,
            ]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getOtherMultipleYear = () => {
      store
        .dispatch("actGetOtherMultipleYear", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.satisfaction = res.data_list.current_data.satisfaction;
            type NameList = keyof GetOtherMultipleYearReq["name_list"];
            type MultipleYearData =
              keyof GetOtherMultipleYearReq["multiple_year_data"];
            for (let key in res.data_list.name_list) {
              if (key === "satisfaction") continue;
              let title = res.data_list.name_list[key as NameList];
              let days = res.data_list.current_data[key as NameList];
              let option = JSON.parse(JSON.stringify(complaints_option));
              option.legend = { data: res.data_list.year_list };
              res.data_list.year_list.forEach((year, ids) => {
                option.series[ids].name = year;
                let years =
                  res.data_list.multiple_year_data[key as MultipleYearData];
                option.series[ids].data = [
                  res.data_list.multiple_year_data[key as MultipleYearData][
                    year
                  ],
                ];
              });
              dataMap.complaintDatas.push({
                title: title,
                days: days,
                option: deepObjectMerges([
                  JSON.parse(JSON.stringify(global_option)),
                  option,
                ]),
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getDecorationMultipleYear();
    getOtherMultipleYear();
    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.site-table :deep(.van-collapse-item__content) {
  padding: 0 !important;
}
</style>