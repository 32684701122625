
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { Collapse, CollapseItem } from "vant";
import TitleLine from "@/components/titleLine/titleLine.vue";

export default defineComponent({
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    TitleLine,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      activeName: "",
      surveyStatistics: [] as { title: string; datas: People[] }[],
    });

    const getSurveyStatistics = () => {
      store
        .dispatch("actGetSurveyStatistics", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            for (let key in res.data_list.type_list) {
              dataMap.surveyStatistics.push({
                title: res.data_list.type_list[key],
                datas: res.data_list.list[key],
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getSurveyStatistics();

    return {
      ...toRefs(dataMap),
      store,
    };
  },
});
