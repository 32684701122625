<template>
  <div class="comprehensive">
    <div class="m-t-10 bg-main">
      <van-collapse v-model="activeName" accordion class="">
        <van-collapse-item
          v-for="(item, index) in surveyStatistics"
          :key="index"
          :name="index"
        >
          <template #title>
            <TitleLine
              :width="22"
              :borderColor="index + 1 + ''"
              :title="item.title + '服务排行（前十）'"
            ></TitleLine>
          </template>
          <div class="people p-l-20" v-for="(el, ids) in item.datas" :key="ids">
            <div class="people-pic">
              <img :src="el.head_image" alt="" class="m-t-10 m-r-20" />
            </div>
            <div class="people-info">
              <p class="m-t-10 f-c-9 f-s-16 f-w-7 font-line-height-30">
                {{ `${el.name}(${el.filiale_name})` }}
              </p>
              <div class="score">
                <span class="f-s-14 f-c-6">平均评分：</span>
                <span class="f-s-12 font-main f-w-8">{{
                  el.average_score
                }}</span>
                <span class="f-s-14 f-c-6">&nbsp;&nbsp;评分个数：</span>
                <span class="f-s-12 font-main f-w-8">{{
                  el.total_score_count
                }}</span>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { Collapse, CollapseItem } from "vant";
import TitleLine from "@/components/titleLine/titleLine.vue";

export default defineComponent({
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    TitleLine,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      activeName: "",
      surveyStatistics: [] as { title: string; datas: People[] }[],
    });

    const getSurveyStatistics = () => {
      store
        .dispatch("actGetSurveyStatistics", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            for (let key in res.data_list.type_list) {
              dataMap.surveyStatistics.push({
                title: res.data_list.type_list[key],
                datas: res.data_list.list[key],
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getSurveyStatistics();

    return {
      ...toRefs(dataMap),
      store,
    };
  },
});
</script>

<style lang="less" scoped>
.comprehensive :deep(.van-icon-arrow::before) {
  margin-top: 12px;
}
.comprehensive :deep(.van-collapse-item__content) {
  padding-top: 0;
}
.people {
  height: 5rem;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0.071429rem solid #ccc;

  .people-pic {
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .people-info {
    flex-grow: 1;

    .people-start {
      img {
        width: 1.285714rem;
        height: 1.142857rem;
      }
    }
  }
}
</style>